import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { t } from 'i18next'

import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import {
  listCampaignPlacesKey,
  listCampaignPlacesService
} from '~/modules/retailMedia/services/campaign-places'

import { schemaPlaces } from './schemas/schemaPlaces'

type Props = {
  campaignId: string
}

const CampaignPlacesTable = ({ campaignId }: Props) => {
  const [page, setPage] = useState(1)
  const [quantity, setQuantity] = useState(50)
  const [panelIsOpen, setPanelIstOpen] = useState(false)

  const QUERY_KEY = [listCampaignPlacesKey, campaignId, page, quantity]

  const { status, data: { data, total } = {} } = useQuery({
    enabled: panelIsOpen,
    queryKey: QUERY_KEY,
    queryFn: async () => {
      const { data } = await listCampaignPlacesService({
        campaignId,
        page,
        quantity,
        count: true
      })
      return data
    }
  })

  return (
    <Panel
      isCollapsible
      initialCollapsibleState={false}
      onCollapse={setPanelIstOpen}
      unmountContentIfCollapse
      title={t('rm:campaignPlaces.table.campaign.locations')}
    >
      <Loading status={status === 'pending'}>
        {t('rm:campaignPlaces.table.loadingLocations')}
      </Loading>

      {!!data?.length && (
        <>
          <ListPagination
            currentPage={page}
            itemsPerPage={quantity}
            menuPlacement="bottom"
            onClickPagination={setPage}
            onItemsPerPageChange={({ value }) => setQuantity(value)}
            total={total}
            label={t('rm:keywords')}
          />

          <Table
            schema={schemaPlaces}
            data={data}
            total={total}
            striped
            sideBorder
            smallerRowPadding
          />

          <ListPagination
            currentPage={page}
            itemsPerPage={quantity}
            menuPlacement="top"
            onClickPagination={setPage}
            onItemsPerPageChange={({ value }) => setQuantity(value)}
            total={total}
            label={t('rm:keywords')}
          />
        </>
      )}
    </Panel>
  )
}

export default CampaignPlacesTable
