import { useState, useMemo, useCallback } from 'react'
import { FiPlus } from 'react-icons/fi'

import { useQuery } from '@tanstack/react-query'

import { Button } from '~/components/Buttons'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { isAdvertiser } from '~/helpers'
import { useSidePanel } from '~/hooks/useSidePanel'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import {
  listCategoriesService,
  listCategoriesServiceKey
} from '~/modules/retailMedia/services/campaign-category'

import FormNewCampaignCategory from './FormNewCategory'
import { schemaCategories } from './schemas/schemaCategories'

type Props = {
  campaignId: string
  publisherId: string
}

const QUERY_KEY = listCategoriesServiceKey

const CampaignCategoryTable = ({ campaignId, publisherId }: Props) => {
  const canEdit = isAdvertiser()

  const [panelIsOpen, setPanelIstOpen] = useState(false)

  const { t, tCapitalize } = useAPPTranslation()

  const { addSidePanel, removeSidePanel } = useSidePanel()

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState<number>(25)
  const [loading, setLoading] = useState(true)

  const { isPending, data: { data, total } = {} } = useQuery({
    enabled: true,
    queryKey: [QUERY_KEY, campaignId, page, perPage],
    queryFn: async () => {
      const { data } = await listCategoriesService({
        campaignId,
        page,
        quantity: perPage
      })
      return data
    }
  })

  const tableCategoryData = useMemo(() => {
    const sort = (a: CampaignCategory, b: CampaignCategory) =>
      a.name.localeCompare(b.name, 'pt-BR', { sensitivity: 'base' })

    const { active = [], inactive = [] } =
      data?.reduce(
        (acc, category) => {
          acc[category.active ? 'active' : 'inactive'].push(category)
          return acc
        },
        { active: [], inactive: [] }
      ) || {}

    return [...active.sort(sort), ...inactive.sort(sort)]
  }, [data])

  const handleOpenPanel = useCallback(() => {
    addSidePanel({
      title: t('rm:categories.add'),
      type: 'content',
      content: (
        <FormNewCampaignCategory
          campaignId={campaignId}
          callback={removeSidePanel}
          publisherId={publisherId}
        />
      )
    })
  }, [addSidePanel, campaignId, publisherId, removeSidePanel, t])

  const handlePagination = (newPage: number) => {
    setLoading(true)
    setPage(newPage)
  }

  const handleItemsPerPage = ({ value }) => {
    setLoading(true)
    setPerPage(value)
  }

  return (
    <Panel
      isCollapsible
      initialCollapsibleState={false}
      onCollapse={setPanelIstOpen}
      unmountContentIfCollapse
      title={tCapitalize('rm:categories')}
      noPaddingBody
    >
      {loading && (
        <Loading status={isPending}>{t('rm:categories.loading')}</Loading>
      )}

      {canEdit && (
        <Button
          onClick={handleOpenPanel}
          text={t('rm:category.add')}
          iconLeft={<FiPlus />}
          size="xsmall"
          className="m-3"
          customWidth="auto"
          template="light"
        />
      )}

      {!!tableCategoryData?.length && (
        <>
          <Table
            schema={schemaCategories}
            data={tableCategoryData}
            total={total}
            striped
            sideBorder
            smallerRowPadding
          />

          <footer>
            <ListPagination
              total={total}
              label={total > 1 ? 'categorias' : 'categoria'}
              currentPage={page}
              itemsPerPage={perPage}
              menuPlacement="top"
              onClickPagination={handlePagination}
              onItemsPerPageChange={handleItemsPerPage}
            />
          </footer>

          {canEdit && (
            <Button
              onClick={handleOpenPanel}
              text={t('rm:category.add')}
              iconLeft={<FiPlus />}
              size="xsmall"
              className="m-3"
              customWidth="auto"
              template="light"
            />
          )}
        </>
      )}
    </Panel>
  )
}

export default CampaignCategoryTable
