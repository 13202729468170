import { useState, useCallback } from 'react'
import { FiPlus } from 'react-icons/fi'

import { useQuery } from '@tanstack/react-query'
import { t } from 'i18next'

import { Button } from '~/components/Buttons'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { getRetailMediaAccountType } from '~/helpers'
import { useSidePanel } from '~/hooks/useSidePanel'
import { listKeywordsService } from '~/modules/retailMedia/services/campaign-keyword'

import FormNewCampaignKeyWord from './FormNewKeyword'
import { schemaKeywords } from './schemas/schemaKeywords'

type Props = {
  campaignId: string
}

const CampaignKeyWordTable = ({ campaignId }: Props) => {
  const [page, setPage] = useState(1)
  const [quantity, setQuantity] = useState(50)
  const [panelIsOpen, setPanelIstOpen] = useState(false)
  const accountType = getRetailMediaAccountType()
  const canEdit = accountType === 'advertiser'
  const { addSidePanel, removeSidePanel } = useSidePanel()

  const QUERY_KEY = ['get-keywords', campaignId, page, quantity]

  const { status, data: { data, total } = {} } = useQuery({
    enabled: panelIsOpen,
    queryKey: QUERY_KEY,
    queryFn: async () => {
      const { data } = await listKeywordsService({
        campaignId,
        page,
        quantity,
        count: true
      })
      return data
    }
  })

  const handleOpenPanel = useCallback(() => {
    addSidePanel({
      title: t('rm:campaign.table.add.keywords'),
      type: 'content',
      content: (
        <FormNewCampaignKeyWord
          campaignId={campaignId}
          callback={removeSidePanel}
        />
      )
    })
  }, [addSidePanel, campaignId, removeSidePanel])

  return (
    <Panel
      isCollapsible
      initialCollapsibleState={false}
      onCollapse={setPanelIstOpen}
      unmountContentIfCollapse
      title={t('rm:keywords')}
      noPaddingBody
    >
      <Loading status={status === 'pending'}>
        {t('rm:campaign.table.keyword.loading')}
      </Loading>

      {canEdit && (
        <Button
          onClick={handleOpenPanel}
          text={t('rm:campaign.table.add.keyword')}
          iconLeft={<FiPlus />}
          size="xsmall"
          className="m-3"
          customWidth="auto"
          template="light"
        />
      )}

      {!!data?.length && (
        <>
          <ListPagination
            currentPage={page}
            itemsPerPage={quantity}
            menuPlacement="bottom"
            onClickPagination={setPage}
            onItemsPerPageChange={({ value }) => setQuantity(value)}
            total={total}
            label={t('rm:keywords')}
          />

          <Table
            schema={schemaKeywords}
            data={data}
            total={total}
            striped
            sideBorder
            smallerRowPadding
          />

          {canEdit && (
            <Button
              onClick={handleOpenPanel}
              text={t('rm:campaign.table.add.keyword')}
              iconLeft={<FiPlus />}
              size="xsmall"
              className="m-3"
              customWidth="auto"
              template="light"
            />
          )}

          <ListPagination
            currentPage={page}
            itemsPerPage={quantity}
            menuPlacement="top"
            onClickPagination={setPage}
            onItemsPerPageChange={({ value }) => setQuantity(value)}
            total={total}
            label={t('rm:keywords')}
          />
        </>
      )}
    </Panel>
  )
}

export default CampaignKeyWordTable
