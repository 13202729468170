import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'

import AlertInfo from '~/components/AlertInfo'
import ListPagination from '~/components/ListPagination'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import Table from '~/components/Table'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import {
  listCampaignPlacementsKey,
  listCampaignPlacementsService
} from '~/modules/retailMedia/services/campaign-placement'

import { schemaPlacements } from './schemas/schemaPlacements'

type Props = {
  campaignId: string
}

const CampaignPlacementsTable = ({ campaignId }: Props) => {
  const [page, setPage] = useState(1)
  const [quantity, setQuantity] = useState(50)
  const [panelIsOpen, setPanelIstOpen] = useState(false)

  const { t } = useAPPTranslation()

  const QUERY_KEY = [listCampaignPlacementsKey, campaignId]

  const {
    status,
    data: { data, total } = {},
    error
  } = useQuery({
    enabled: panelIsOpen,
    queryKey: QUERY_KEY,
    queryFn: async () => {
      const { data } = await listCampaignPlacementsService({
        campaignId
      })
      return data
    }
  })

  return (
    <Panel
      isCollapsible
      initialCollapsibleState={false}
      onCollapse={setPanelIstOpen}
      unmountContentIfCollapse
      title={t('rm:campaignPlacements')}
    >
      <Loading status={status === 'pending'}>
        {t('rm:campaignPlacements.loading')}
      </Loading>

      {total === 0 && (
        <AlertInfo template="warning">
          {t('rm:campaignPlacements.noPlacements')}
        </AlertInfo>
      )}

      {error && (
        <AlertInfo template="danger">
          {t('rm:campaignPlacements.loadError')}
        </AlertInfo>
      )}

      {!!total && (
        <>
          <ListPagination
            currentPage={page}
            itemsPerPage={quantity}
            menuPlacement="bottom"
            onClickPagination={setPage}
            onItemsPerPageChange={({ value }) => setQuantity(value)}
            total={total}
            label="placements"
          />

          <Table
            schema={schemaPlacements}
            data={data}
            total={total}
            striped
            sideBorder
            smallerRowPadding
          />

          <ListPagination
            currentPage={page}
            itemsPerPage={quantity}
            menuPlacement="top"
            onClickPagination={setPage}
            onItemsPerPageChange={({ value }) => setQuantity(value)}
            total={total}
            label="placements"
          />
        </>
      )}
    </Panel>
  )
}

export default CampaignPlacementsTable
