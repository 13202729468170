import { useEffect, useRef, useState } from 'react'
import { FiSearch } from 'react-icons/fi'

import { t } from 'i18next'
import { debounce } from 'lodash'

import Input from '~/components/Form/Input'
import { history, isPublisher } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'

import FilterAdStatus from '../../Filters/FilterAdStatus'
import FilterAdtype from '../../Filters/FilterAdTypes'
import FilterPublisherTag from '../../Filters/FilterPublisherTag'
import FilterTargetingType from '../../Filters/FilterTargetingType'

type AdListFiltersProps = {
  adType?: CampaignAdType
}

const AdListFilters = ({ adType }: AdListFiltersProps = {}) => {
  const [innerLoading, setInnerLoading] = useState(false)

  const insideCampaign = !!adType

  const searchParams = useQueryParams()

  const product_sku = searchParams.get('product_sku')
  const campaign_name = searchParams.get('campaign_name')

  const [data, setData] = useState({
    product_sku,
    campaign_name
  })

  useEffect(() => {
    setData({
      product_sku: product_sku || '',
      campaign_name: campaign_name || ''
    })
  }, [campaign_name, product_sku])

  const executeDebounceFunc = (func: () => void) => func()

  const handleDebounce = useRef(debounce(executeDebounceFunc, 1000)).current

  const handleFilter = (data: OnChangeInputData) => {
    const { name, value } = data

    if (!value) {
      const newParams = new URLSearchParams(searchParams.toString())
      newParams.delete(name)
      history.push({ search: newParams.toString() })
      setInnerLoading(false)

      return
    }

    searchParams.set(name, String(value))
    searchParams.set('page', '1')
    history.push({ search: searchParams.toString() })

    setInnerLoading(false)
  }

  const handleChange = (data: OnChangeInputData) => {
    setInnerLoading(true)

    setData(prevState => ({ ...prevState, [data.name]: data.value }))

    handleDebounce(() => handleFilter(data))
  }

  const isSingleAdCampaign = adType === 'sponsored_brand'
  const isProduct = adType === 'product'

  return (
    !isSingleAdCampaign && (
      <form className="d-flex gap-2 mb-2">
        {!insideCampaign && (
          <Input
            name="campaign_name"
            onChange={handleChange}
            value={data.campaign_name}
            icon={FiSearch}
            placeholder={t('rm:filterByCampaignName')}
            type="search"
            loading={innerLoading}
          />
        )}

        {(!insideCampaign || isProduct) && (
          <Input
            name="product_sku"
            onChange={handleChange}
            value={data.product_sku}
            icon={FiSearch}
            placeholder={t('rm:filterBySKU')}
            type="search"
            loading={innerLoading}
          />
        )}

        <FilterAdStatus />

        {!insideCampaign && (
          <>
            <FilterAdtype />

            <FilterTargetingType />

            {isPublisher() && <FilterPublisherTag />}
          </>
        )}
      </form>
    )
  )
}

export default AdListFilters
