import { queryDatesDatepickerIsolated, isAdvertiser } from '~/helpers'
import { SWRSortProvider } from '~/hooks/useSWRSort'
import { reatailmediaApi } from '~/modules/retailMedia/services/api'
import { useAppSelector } from '~/store/hooks'

import AddProductsWrapper from './AddProductsWrapper'
import Table from './Table'

const ProductTable = ({
  campaignId,
  adType = null,
  publisherId
}: {
  campaignId: string
  publisherId: string
  adType?: CampaignAdType
}) => {
  const canEdit = isAdvertiser()

  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  return (
    <SWRSortProvider
      extraParams={{
        campaign_id: campaignId,
        ...queryDatesDatepickerIsolated(startDate, endDate)
      }}
      refreshInterval={1000 * 60}
      url={`${reatailmediaApi}/product/results`}
    >
      {canEdit && <AddProductsWrapper publisherId={publisherId} />}
      <Table adType={adType} />
    </SWRSortProvider>
  )
}

export default ProductTable
