import { useCallback, useMemo, useRef } from 'react'
import { FiPlus } from 'react-icons/fi'
import { useParams } from 'react-router-dom'

import { Button } from '~/components/Buttons'
import { formatDateToString } from '~/helpers'
import { useSidePanel } from '~/hooks/useSidePanel'
import { useSWRSort } from '~/hooks/useSWRSort'
import { useAPPTranslation } from '~/i18n/useAPPTranslation'
import { useAppSelector } from '~/store/hooks'

import { AddRelatedProduct } from '../../AddRelatedProduct'

interface Props {
  publisherId: string
}

const AddProductsWrapper: React.FC = ({ publisherId }: Props) => {
  const { t } = useAPPTranslation()
  const { id } = useParams()
  const { addSidePanel, removeSidePanel } = useSidePanel()
  const formRef = useRef(null)
  const { refreshData } = useSWRSort()

  const {
    campaign: {
      item: { data }
    }
  } = useAppSelector(state => state.retailMedia)

  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  const queryParams = useMemo(
    () =>
      ({
        start_date: formatDateToString(startDate, 'YYYY-MM-DD'),
        end_date: formatDateToString(endDate, 'YYYY-MM-DD'),
        campaign_id: id,
        count: false
      }) as RequestCampaignGet,
    [startDate, endDate, id]
  )

  const canEdit = useMemo(() => data?.canEditCampaign, [data?.canEditCampaign])

  const allowAction = useMemo(
    () => ['banner'].includes(data?.adType),
    [data?.adType]
  )

  const handleClickOnSave = useCallback(() => {
    if (formRef.current) {
      formRef.current.submitForm()
    }
  }, [])

  const handleSuccess = useCallback(() => {
    refreshData()
  }, [refreshData])

  // Handle open panel
  const handleOpenPanel = useCallback(() => {
    addSidePanel({
      title: t('rm:products.add'),
      type: 'content',
      size: 'large',
      content: (
        <AddRelatedProduct
          adType="relatedproduct"
          showEdit
          formRef={formRef}
          queryParams={queryParams}
          handleClickOnSave={handleClickOnSave}
          handleClose={removeSidePanel}
          publisherId={publisherId}
          onSuccess={handleSuccess}
        />
      )
    })
  }, [
    addSidePanel,
    handleClickOnSave,
    handleSuccess,
    publisherId,
    queryParams,
    removeSidePanel,
    t
  ])

  return (
    <>
      {allowAction && canEdit && (
        <Button
          onClick={handleOpenPanel}
          text={t('rm:products.add')}
          iconLeft={<FiPlus />}
          size="xsmall"
          className="m-3"
          customWidth="auto"
          template="light"
        />
      )}
    </>
  )
}

export default AddProductsWrapper
